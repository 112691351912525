<template>
	<div class="login-page">
		
		<img v-if="isCRM" src="https://cdn.dkycn.cn/images/melyshop/2024091213520002.jpg" style="width: 100%;height: 100%" class="bg">
		<img v-if="!isCRM" src="https://cdn.dkycn.cn/images/melyshop/login-bg-1.png" style="width: 100%;height: 100%" class="bg">
		<div v-if="!isCRM" class="bg1">
			<img src="https://cdn.dkycn.cn/images/melyshop/login-bg-2.png">
			<div class="txt">
				<div class="txt1">企店·私域运营工具</div>
				<div class="txt2">做私域用企店<span style="color: #409EEF;">免费易用</span></div>
			</div>
		</div>
		<img v-if="!isCRM" src="https://cdn.dkycn.cn/images/melyshop/login-bg-3.png" class="bg2">
		<div v-if="!isCRM" class="bg3">
			<a href="https://open.work.weixin.qq.com" target="_blank"><img src="https://open.work.weixin.qq.com/service/img?id=wweace29825590581f&t=isp&c=white&s=medium" srcset="https://open.work.weixin.qq.com/service/img?id=wweace29825590581f&t=isp&c=white&s=medium@2x 2x" referrerpolicy="unsafe-url" alt="企业微信"></a>
		</div>
		
		<div class="login-wraper" :class="{'login-wraper-crm': isCRM}">
			<div v-if="isCRM" class="crm-title">欢迎登录CRM!</div>
			<div class="login-box">
				<div class="login-tabs">
					<div class="login-tab" :class="{current: item.type == loginType}" v-for="(item, index) in loginTypeList"
						:key="index" @click="loginType=item.type">
						{{item.label}}
					</div>
				</div>
				
				<div class="login-item">
					<el-input v-model="account" placeholder="请输入登录手机号" autocomplete="on"></el-input>
				</div>
				<div class="login-item" v-if="loginType == 1">
					<el-input v-model="password" :type="openPassword?'text':'password'" autocomplete="on"
						placeholder="请输入登录密码" @keyup.enter.native="handleLogin"></el-input>
					<div class="icon" @click="openPassword=!openPassword">
						<img src="@/assets/img/no_eye.png" v-show="!openPassword">
						<img src="@/assets/img/eye.png" v-show="openPassword">
					</div>
					<div class="line"></div>
					<div class="forget" @click="handleToForget">忘记密码</div>
				</div>
				<div class="login-item" v-if="loginType == 2">
					<el-input v-model="code" type="text" placeholder="请输入验证码"></el-input>
					<div class="forget" @click="getSmsCode" v-if="!second">获取验证码</div>
					<div class="forget" v-else>{{second}}s</div>
				</div>
				
				<el-button type="primary" size="large" style="margin-left: 50px;margin-top: 60px;width: 450px;height: 48px;font-size: 18px;"
					@click="handleLogin">登录</el-button>
				
				<div class="login-tip" v-if="!isCRM">
					还未开通企店？<el-link type="primary" style="font-size: 16px;" @click="handleToOpen">免费开通</el-link>
				</div>
			</div>
		</div>
		
		

		<a class="alimama-info" target="_blank" href="https://beian.miit.gov.cn" v-if="!isCRM">
			Copyright © 2017 - 2021
			企店. All Rights Reserved 
			<span>{{alimama}}</span>
		</a>

		<el-dialog :visible.sync="showImgCode" width="540px" title="请先完成图片验证" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
			<el-form ref="form" label-width="110px">
				<el-form-item label="图片验证码:">
					<div class="form-wraper">
						<el-input v-model="imgCode" style="width: 200px;"></el-input>
						<img :src="imgCodeUrl">
					</div>
					<div class="form-tip">
						<el-link type="primary" @click="getImgCode">看不清？换一张</el-link>
					</div>
				</el-form-item>
			</el-form>
			
			<div slot="footer" class="dialog-footer">
			      <el-button @click="showImgCode = false;imgCode=''">取 消</el-button>
			      <el-button type="primary" @click="getSmsCode">确 认</el-button>
			    </div>
		</el-dialog>

	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex'
	import {
		smsendsmscode,
		smsimgcode,
		resetloginpassword,
		employeemalllist
	} from '@/api/login'
	
	export default {
		name: 'login',
		data() {

			return {
				loginType: 1,
				loginTypeList: [{
					type: 1,
					label: '密码登录'
				}, {
					type: 2,
					label: '验证码登录'
				}],

				// 账密
				account: '',
				password: '',
				openPassword: false, //是否明文显示密码
				code: '',
				imgCode: '',
				imgCodeUrl: '',
				second: 0,
				showImgCode: false,
				
				alimama: '',
				isCRM: location.origin.indexOf('citta.zmget.com') > -1,
			}
		},
		components: {},
		computed: {
			...mapGetters([
				'loginState',
				'ShopName',
				'ShopLogo',
			])
		},
		watch: {},

		created() {
			console.log('2222222222')
			this.alimama = process.env.NODE_ENV == 'production' ? (process.env.VUE_APP_CURRENTMODE == 'stage' ?
				'浙ICP备20002894号-4' : '浙ICP备20002894号-5') : '浙ICP备20002894号-4'
			localStorage.setItem('mlmzMallKey', '');
			
			
		},
		mounted() {},
		methods: {
			
			handleLogin() {
				if (this.loginType == 1) {
					this.passwordLogin();
				}else{
					this.codeLogin();
				}
			},

			//密码登录
			passwordLogin() {
				if (!this.validLoginParams()) return;

				this.$store.dispatch('Login', {
					username: this.account,
					password: this.password,
					rememberme: true,
				}).then(() => {
					if (this.loginState) {
						this.loading = false
						this.$router.push({
							path: '/'
						})
					} else {
						this.$message.error('用户名或者密码错误');
					}
				}).catch((e) => {
					console.log(e)
					this.loading = false
				})

			},
			
			validLoginParams() {
				if (!this.account) {
					this.$message.error('请输入登录手机号')
					return false
				}

				if (!/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(this.account)) {
					this.$message.error('请输入正确的手机号')
					return false
				}

				if (!this.password) {
					this.$message.error('请输入密码')
					return false
				}

				return true;
			},

			async getSmsCode() {
				
				if (!this.account) {
					this.$message.error('请输入登录手机号')
					return false
				}
				
				if (!/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(this.account)) {
					this.$message.error('请输入正确的手机号')
					return false
				}
				
				if (this.isSeed) return;
				this.isSeed = true;
				
				try {
					let params = {
						Phone: this.account,
						ImgCode: this.imgCode,
						noError: true,
						Type: 0
					};
					let res = await smsendsmscode(params)
					if (res.IsSuccess) {
						this.$message.success('验证码已发送至您的手机，请注意查收')
						this.second = 59;
						this.showImgCode = false;
						this.imgCode = '';
						this.runTimer();
					} else if (res.ErrorCode == 1000060) {
						this.showImgCode = true;
						this.$message.error(res.Message);
						this.getImgCode();
					} else {
						this.$message.error(res.Message);
						this.getImgCode();
					}
				} catch (err) {
					
				}finally{
					this.isSeed = false
				}
			},
			async getImgCode() {
				try {
					let data = {
						Phone: this.account
					}
					let res = await smsimgcode(data)
					if (res.img) {
						this.imgCodeUrl = res.img;
					}
				} catch (err) {

				} finally {

				}
			},
			runTimer() {
				if (this.second <= 0) return;
				setTimeout(() => {
					this.second -= 1;
					this.runTimer();
				}, 1000);
			},
			
			async codeLogin(){
				if (!this.validCodeLoginParams()) return;
				
				this.$store.dispatch('codeLogin', {
					LoginPhone: this.account,
					SMSCode: this.code
				}).then(() => {
					if (this.loginState) {
						this.loading = false
						this.$router.push({
							path: '/'
						})
					} else {
						this.$message.error('登录失败');
					}
				}).catch((e) => {
					this.loading = false
				})
			},
			validCodeLoginParams() {
				if (!this.account) {
					this.$message.error('请输入登录手机号')
					return false
				}
			
				if (!/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(this.account)) {
					this.$message.error('请输入正确的手机号')
					return false
				}
			
				if (!this.code) {
					this.$message.error('请输入短信验证码')
					return false
				}
			
				return true;
			},
			
			handleToOpen(){
				this.$router.replace({
					path: '/freeOpen'
				})
			},
			handleToForget(){
				this.$router.replace({
					path: '/forget'
				})
			}
		},
	}
</script>

<style lang="less" scoped>
	.login-page {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		color: #303133;
		
		.crm-title{
			font-size: 30px;
			line-height: 45px;
			margin-bottom: 20px;
			font-weight: bold;
		}

		.bg {
			display: block;
			width: 100%;
		}
		.bg1{
			position: absolute;
			right: 700px;
			top: 26%;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			
			img{
				display: block;
				width: auto;
				height: auto;
			}
			
			.txt{
				position: absolute;
				right: 240px;
				top: -75px;
				
				.txt1{
					font-size: 58px;
					color: #8B94A6;
				}
				.txt2{
					font-size: 32px;
					color: #9E9E9E;
					margin-top: 20px;
				}
			}
		}
		.bg2{
			position: absolute;
			left: 30px;
			top: 30px;
			width: 161px;
			height: 39px;
		}
		.bg3{
			position: absolute;
			left: 210px;
			top: 37px;
		}
		

		.login-wraper {
			position: absolute;
			right: 150px;
			top: 50%;
			transform: translateY(-50%);
			
			.login-box{
				background-color: #fff;
				box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
				border-radius: 6px;
				width: 550px;
				height: 514px;
			}

			.login-tabs {
				padding: 40px 50px;
				display: flex;
				align-items: baseline;
				margin-bottom: 20px;

				.login-tab {
					font-size: 20px;
					color: #999;
					margin-right: 30px;
					cursor: pointer;
				}

				.current {
					font-size: 26px;
					color: #303133;
				}
			}

			.login-item {
				height: 80px;
				margin: 0 50px;

				display: flex;
				align-items: center;
				overflow: hidden;
				border-bottom: 1px solid #ddd;

				::v-deep input {
					flex: 1 1 auto;
					height: 80px;
					font-size: 16px;
					border: none;
					box-shadow: inset 0 0 0 1000px #fff!important;
				}

				.icon {
					flex: 0 0 auto;
					width: 20px;
					cursor: pointer;

					img {
						display: block;
						width: 100%;
					}
				}

				.line {
					flex: 0 0 auto;
					width: 1px;
					height: 14px;
					background-color: #ddd;
					margin-left: 10px;
				}

				.forget {
					flex: 0 0 auto;
					padding: 0 10px;
					font-size: 16px;
					color: #409EEF;
					white-space: nowrap;
					cursor: pointer;
				}
			}

			.login-tip {
				display: flex;
				justify-content: center;
				color: #606266;
				font-size: 16px;
				margin-top: 30px;
			}
		}
		
		.login-wraper-crm{
			right: 50%;
			top: 50%;
			transform: translate(50%, -50%);
		}
	}
	
	.form-wraper{
		display: flex;
		align-items: center;
		
		img{
			display: block;
			width: 130px;
			margin-left: 10px;
		}
	}
	.form-tip{
		margin-left: 228px;
		line-height: 20px;
		margin-top: 5px;
	}
	
	.alimama-info{
		position: fixed;
		left: 0;
		width: 100%;
		bottom: 20px;
		text-align: center;
	}
</style>
